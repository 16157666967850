import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: () => import("../views/chargeView.vue"), // stripeからリダイレクト時に呼ばれる
    },
    {
      path: "/purchasehistory",
      component: () => import("../views/purchasehistory.vue"),
    },
    {
      path: "/payment",
      component: () => import("../views/paymentView.vue"),
    },
    {
      path: "/purchasecommit",
      component: () => import("../views/_PurchaseCommitView.vue"),
    },
    {
      path: "/qrcode",
      component: () => import("../views/qrDispView.vue"),
    },
    {
      path: "/charge",
      component: () => import("../views/chargeView.vue"),
    },
    {
      path: "/userInitial",
      component: () => import("../views/userInitialView.vue"),
    },
  ],
});

export default router;
